import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { Button } from 'reactstrap';
import renderRadioButtonField from '~~/form/RadioButton';
import CheckoutForm from './CheckoutForm';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { colorAdditional } from '~/utils/palette';

const CartPurchase = () => {
  const [options, setOptions] = useState();
  const { cart, cartInfoList } = useSelector(state => state.cart);
  const { stripePromise } = useSelector((state) => state.marketplace);

  useEffect(async () => {
    if (cartInfoList.length > 0) {
      setOptions({
        mode: 'payment',
        amount: cartInfoList.reduce((acc, cur) => (acc + Number(cur.price)), 0).toFixed(2) * 100,
        currency: 'usd',
        // Fully customizable with appearance API.
      });
    }
  }, [cartInfoList]);


  return (
    // <Form onSubmit={() => {}} initialValues={{ delivery: 'russian_post' }}>
    //   {({ handleSubmit }) => (
    //     <CartDeliveriesForm onSubmit={handleSubmit}>
    //       <CartTotal>Total Price: $348.00</CartTotal>
    //       <FormButtonToolbar>
    //         <Button as={Link} variant="primary" to="/e-commerce/payment">Purchase</Button>
    //       </FormButtonToolbar>
    //     </CartDeliveriesForm>
    //   )}
    // </Form>
    <>
      {options && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
};

export default CartPurchase;

// region STYLES

const CartDeliveriesForm = styled(FormContainer)`
  margin-top: 20px;
`;

const CartDelivery = styled.div`
  margin-right: 50px;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0;
  }

  & > label {
    margin-bottom: 0;
  }
  
`;

const CartDeliveryField = styled(FormGroupField)`
  flex-wrap: wrap;
`;

const CartTotal = styled.h4`
  width: 100%;
  font-weight: 700;
  margin-bottom: 5px;
`;

const CartDeliveryPrice = styled.p`
  font-size: 10px;
  line-height: 13px;
  margin: 0;
  padding-left: 27px;
`;

const CartDeliveryTime = styled(CartDeliveryPrice)`
  color: ${colorAdditional};
  margin-bottom: 8px;
`;

// endregion
